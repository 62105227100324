<template>
  <div class="DAV-loader">
    <div class="dualball"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.DAV-loader {
  z-index: 101;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.4);

  .dualball {
    width: 100px;
    height: 100px;
    background-image: url($path + "dualball.svg");
    background-repeat: no-repeat;
  }
}
</style>
